import React, { useContext, useState } from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import QuizOptions from './options'
import QuizOptionsNumeracao from './optionNumeracao'
import Button from '../../Buttons/button'
import NavLink from '../../Buttons'
import { QuizCorridaContext } from '../../../Context/quizCorrida'
import { GlobalContext } from '../../../Context/global'
import ProgressBar from './progressBar'
import data from '../../../data/quizTotem'
import data_es from '../../../data/quizTotem'
import Radio from '../../Form/radio'
import * as S from './styles'
import PropTypes from 'prop-types'

const QuizTipoCorridaTotem = ({ loja }) => {
  const {
    handleSelectOptionTotem,
    handleLoadNextQuestionTotem,
    handleLoadPrevQuestionTotem,
    perguntaAtualTotem,
    pontuacaoTotem,
    pontuacaoTotemNumeracao,
    disabledTotem,
    totalPerguntasTotem,
    dadosPerguntaSelecionadaTotem,
    handleSelectOptionTotemNumeracao
  } = useContext(QuizCorridaContext)

  const { language } = useContext(GlobalContext)
  const arrQuiz = language === 'br' ? data : data_es
  const languageBr = language === 'br'
  const porcentagem = Math.round(
    (perguntaAtualTotem / totalPerguntasTotem) * 100
  )

  const [checkedState, setCheckedState] = useState(
    new Array(arrQuiz.tipoCorrida[perguntaAtualTotem].tamanho).fill(false)
  )


  return (
    <S.QuizWrapper>
      <S.Progressbar>
        <ProgressBar completed={porcentagem} />
      </S.Progressbar>

      <S.QuestionTitle>
        {arrQuiz.tipoCorrida[perguntaAtualTotem].titulo}
      </S.QuestionTitle>
      <S.QuesionList className={arrQuiz.tipoCorrida[perguntaAtualTotem].slug}>
        {arrQuiz.tipoCorrida[perguntaAtualTotem].alternativas.map(
          (alternativa, index) => (
            <S.QuestionOption key={alternativa.titulo}>
              <QuizOptions
                id={`pergunta${index + 1}`}
                alternativas={alternativa.titulo}
                peso={alternativa.peso}
                score={pontuacaoTotem}
                img={alternativa.img}
                temicon={alternativa.temicon}
                temtexto={alternativa.temtexto}
                texto={alternativa.texto}
                handleSelectOption={() =>
                  handleSelectOptionTotem(
                    alternativa.peso,
                    arrQuiz.tipoCorrida[perguntaAtualTotem].slug
                  )
                }
              />
            </S.QuestionOption>
          )
        )}
      </S.QuesionList>
      <S.QuesionList>
      {arrQuiz.tipoCorrida[perguntaAtualTotem].tamanho &&
          ( <div className='numeracao'>
              {arrQuiz.tipoCorrida[perguntaAtualTotem].tamanho.map(
                (tamanho,index) => (
                  <S.QuestionOption key={tamanho.num}>
                    <QuizOptionsNumeracao 
                      id={`numero${index + 1}`}
                      alternativas={tamanho.num}
                      peso={tamanho.num}
                      score={pontuacaoTotemNumeracao}
                      img={tamanho.num}
                      handleSelectOptionNumeracao={() =>
                        handleSelectOptionTotemNumeracao(
                          tamanho.num,
                          arrQuiz.tipoCorrida[perguntaAtualTotem].slug
                        )
                      }
                    />
                  </S.QuestionOption>
                )
              )}
          </div> )
        }
      </S.QuesionList>
      {languageBr ? (
        <S.ButtonsWrapper>
          {perguntaAtualTotem + 1 > 1 && (
            <Button type="button" onClick={handleLoadPrevQuestionTotem}>
              <Trans>Anterior</Trans>
            </Button>
          )}

          {perguntaAtualTotem === totalPerguntasTotem - 1 ? (
            <NavLink
              to={`/totem/${loja.slug}/quiz/resultado`}
              state={{ dadosCorrida: dadosPerguntaSelecionadaTotem }}
              className={disabledTotem && 'disabled'}
              size="small"
              id="btn-quiz-resultado"
            >
              <Trans>Continuar</Trans>
            </NavLink>
          ) : (
            <Button
              type="button"
              onClick={handleLoadNextQuestionTotem}
              disabled={disabledTotem}
            >
              <Trans>continuar</Trans>
            </Button>
          )}
        </S.ButtonsWrapper>
      ) : (
        <S.ButtonsWrapper>
          {perguntaAtualTotem + 1 > 1 && (
            <Button type="button" onClick={handleLoadPrevQuestionTotem}>
              <Trans>Anterior</Trans>
            </Button>
          )}

          {perguntaAtualTotem === totalPerguntasTotem - 1 ? (
            <NavLink
              to={`/totem/${loja.slug}/quiz/resultado`}
              state={{ dadosCorrida: dadosPerguntaSelecionadaTotem }}
              className={disabledTotem && 'disabled'}
              size="small"
              id="btn-quiz-resultado"
            >
              <Trans>Continuar</Trans>
            </NavLink>
          ) : (
            <Button
              type="button"
              onClick={handleLoadNextQuestionTotem}
              disabled={disabledTotem}
            >
              <Trans>Continuar</Trans>
            </Button>
          )}
        </S.ButtonsWrapper>
      )}
    </S.QuizWrapper>
  )
}

QuizTipoCorridaTotem.propTypes = {
  loja: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export default QuizTipoCorridaTotem

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
