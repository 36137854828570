import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import Container from '@material-ui/core/Container'
import SEO from '../../../components/SEO'
import Layout from '../../../components/totem/layout'
import QuizTipoCorridaTotem from '../../../components/totem/Quiz/quizTipoCorrida'
import ErrorBoundary from '../../../components/ErrorBoundary'
import { ditoTrack } from '../../../helpers/dito'

import * as S from '../quiz/styles'
import PropTypes from 'prop-types'
import BtnVoltar from '../../../components/totem/Voltar'

const QuizPage = ({ pageContext: { loja } }) => {
  useEffect(() => {
    ditoTrack('acessou-quiz')
  }, [])

  const { t } = useTranslation()

  return (
    <Layout loja={loja}>
      <SEO title={t('quiz_title_seo')} description={t('description_seo')} />
      <S.Bg>
        <Container>
          <S.PageTitle>
            <Trans>
              ENTENDA COMO O SEU ESTILO DE VIDA PODE DEFINIR O SEU TIPO IDEAL DE
              CORRIDA.
            </Trans>
          </S.PageTitle>
          <ErrorBoundary>
            <QuizTipoCorridaTotem loja={loja} />
          </ErrorBoundary>
        </Container>
      </S.Bg>
      <BtnVoltar></BtnVoltar>
    </Layout>
  )
}

QuizPage.propTypes = {
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default QuizPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
