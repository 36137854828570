import React from "react";

const ProgressBar = (props) => {
    const { completed } = props;
  
    const containerStyles = {
      height: 23,
      width: '100%',
      backgroundColor: "white",
      border: '1px solid #001E62',
      borderRadius: 50,
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: '#001E62',
      borderRadius: 'inherit',
      textAlign: 'right'
    }
  
    const labelStyles = {
      padding: '5px 5px 2px 5px',
      color: 'white',
      fontWeight: 'bold',
      backgroundColor: '#001E62',
      borderRadius: 50,
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${completed}%`}</span>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;