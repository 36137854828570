import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const Button = ({ children, type = 'button', ...props }) => (
  <S.Button type={type} {...props}>
    {children}
  </S.Button>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
}

export default Button
