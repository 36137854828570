import React from 'react'
import PropTypes from 'prop-types'
import Radio from '../../Form/radio'


const QuizOptionsNumeracao = ({
  id,
  alternativas,
  peso,
  score,
  handleSelectOptionNumeracao,
}) => (
  <>
    <Radio
      name="Numeracao"
      id={id}
      value={peso}
      onChange={handleSelectOptionNumeracao}
      checked={score === peso}
    />
    <label htmlFor={id}>
      <div>
        {alternativas}
      </div>
       
    </label>
  </>
)

QuizOptionsNumeracao.propTypes = {
  id: PropTypes.string.isRequired,
  alternativas: PropTypes.number.isRequired,
  peso: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  handleSelectOptionNumeracao: PropTypes.func,
}

export default QuizOptionsNumeracao
